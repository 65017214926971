import React from "react"

import { Layout } from "../components/layout"

import { graphql } from "gatsby"
import { LazyBlockFactory } from "../util/LazyBlockFactory"
import GridHomeHeader from "../components/Home/GridHomeHeader"
import { Container } from "@material-ui/core"

import { InstagramFeed } from "../components/InstagramFeed"

const headings = ["Sustainability", "Diversity", "Heritage", "Award Nominees"]

const HomeEvent: React.FC = ({ data }) => {
  const portfolios = data.allWpPortfolio.edges.map(v => v.node)

  const home = data.wpPage
  const blocks = LazyBlockFactory(home.lazy_data, data.wpPage.title)

  return (
    <Layout title={"Graduate Fashion Week - Home"}>
      <GridHomeHeader title={home.HomeData.headingTitle} body={home.HomeData.headingDescription}
      
      address={home.HomeData.address}
      bottomRowHidden={home.HomeData.bottomRowHidden}
      cta1IsHidden={home.HomeData.cta1IsHidden}
      cta1Label={home.HomeData.cta1Label}
      cta1Url={home.HomeData.cta1Url}
      cta2IsHidden={home.HomeData.cta2IsHidden}
      cta2Label={home.HomeData.cta2Label}
      cta2Url={home.HomeData.cta2Url}
      endDate={home.HomeData.endDate}
     
      startDate={home.HomeData.startDate}
      image={home.HomeData.homepageHeaderImage}
      />
      <Container maxWidth={"lg"}>{blocks.map(v => v)}</Container>
      {/* <FeaturedPortfoliosCarousel portfolios={portfolios} headings={headings} /> */}
      {/* <KeynoteSpeakers /> */}
      <InstagramFeed />
    </Layout>
  )
}

export default HomeEvent
export const pageQuery = graphql`
  query homeevent {

    allWpPortfolio {
      edges {
        node {
          id
          title
          slug
          student_portfolio {
            aboutMeHeading
          }
          student_profile {
            studentName
            specialism
            searchImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(title: { eq: "Home Event" }) {
      id
      title
      HomeData {
        address
        bottomRowHidden
        cta1IsHidden
        cta1Label
        cta1Url
        cta2IsHidden
        cta2Label
        cta2Url
        endDate
        fieldGroupName
        getInTouchFooterBody
        getInTouchFooterCat
        getInTouchFooterHeading
        headingDescription
        headingTitle
        startDate
        homepageHeaderImage {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      }
      lazy_data
    }
  }
`
