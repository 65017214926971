import { Container } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button, ButtonSecondary } from "../Buttons/Buttons"
import Cross from "../../assets/svg/GFW_cross.svg"
import { DateTime } from "luxon"

interface HeaderI {
  title: string
  body: string
  address: string
  bottomRowHidden
  cta1IsHidden: boolean
  cta1Label: string
  cta1Url: string
  cta2IsHidden: boolean
  cta2Label: string
  cta2Url: string
  endDate: string
  image: any
  startDate: string
}

const GridHomeHeader: React.FC<HeaderI> = ({
  title,
  body,
  address,
  bottomRowHidden,
  cta1IsHidden,
  cta1Label,
  cta1Url,
  cta2IsHidden,
  cta2Label,
  cta2Url,
  endDate,
  image,
  startDate,
}) => {
  const string = body
  const length = 175
  const trimmedString = string.length > length ? string.substring(0, length - 3) + "..." : string
  const startDateLuxon = DateTime.fromFormat(startDate, "dd/MM/yyyy")
  const endDateLuxon = DateTime.fromFormat(endDate, "dd/MM/yyyy")
  const headerimage = useStaticQuery(graphql`
    {
      placeholderImage: file(name: { eq: "hp_header" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <MainContainer>
      <BackgroundColor>
        <BackgroundPattern />
        <StyledHeaderImg
          image={image.localFile.childImageSharp.gatsbyImageData}
          imgStyle={{ objectFit: "cover" }}
          // placeholderStyle={}
          loading={"eager"}
          alt={"Graduate fashion week hero image"}
        />

        <TransparentPlacer>
          <TransparentBox />
        </TransparentPlacer>
      </BackgroundColor>
      <GridContainer>
        <TextBoxCard>
          <TextBoxCardContent>
            <H1Header>{title}</H1Header>
            <PHeader>{body.length > length ? body.substring(0, length - 3) + "..." : body}</PHeader>
            {/* <div
              style={{
                display: "flex",
                // maxWidth: "100%",
                flexDirection: "row",
                // border: "1px solid red",
                justifyContent: "space-between",
                maxWidth: 530,
              }}
            > */}
            <ButtonContainer>
              <div style={{ display: cta1IsHidden ? "none" : "" }}>
                <Button to={cta1Url} customwidth={"245px"}>
                  {cta1Label}
                </Button>
              </div>
              <div style={{ display: cta2IsHidden ? "none" : "" }}>
                <ButtonSecondaryCont to={cta2Url} customwidth={"245px"}>
                  {cta2Label}
                </ButtonSecondaryCont>
              </div>
              
            </ButtonContainer>

            <LineCrossContainer style={{ display: bottomRowHidden ? "none" : "flex" }}>
              <Line />
              <Cross width="30px" height="30px" />
              <Line />
            </LineCrossContainer>
            <LocationRow style={{ display: bottomRowHidden ? "none" : "flex" }}>
              <DateContainer>
                <DateBox>
                  <h4>{startDateLuxon.day}</h4>
                  <p>{startDateLuxon.toLocaleString({ month: "long", year: "numeric" })}</p>
                </DateBox>
                <DateLineBox>
                  <h4>-</h4>
                </DateLineBox>
                <DateBox>
                  <h4>{endDateLuxon.day}</h4>
                  <p>{endDateLuxon.toLocaleString({ month: "long", year: "numeric" })}</p>
                </DateBox>
              </DateContainer>
              <LocationContainer>
                {/* <h5>Coal Drops Yard</h5>

                <h5 style={{ lineHeight: 1 }}>Kings Cross, London</h5> */}
                <h5>{address}</h5>
              </LocationContainer>
            </LocationRow>
          </TextBoxCardContent>
        </TextBoxCard>
        <ImageBox>
          {/* <StyledHeaderImg
            fluid={headerimage.placeholderImage.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
          /> */}
        </ImageBox>
      </GridContainer>
    </MainContainer>
  )
}

export default GridHomeHeader

const MainContainer = styled.div`
  width: 100vw;
  margin-bottom: 80px;
`
const GridContainer = styled(Container)`
  height: 780px;
  max-height: 900px;
  display: grid !important;
  grid-template-columns: 1fr 80px 1fr;
  grid-template-rows: 20px 100px 1fr 40px 40px 40px 40px;
  z-index: 1;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: 20px 1fr 20px;
    grid-template-rows: 20px 330px 100px 1fr 40px 40px 80px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 1000px;
  }
`

const ButtonSecondaryCont = styled(ButtonSecondary)`
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 10px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-left: 0px;
    margin-top: 10px;
  }
`

const TextBoxCard = styled.div`
  /* border-top: 4px solid white;
  background-color: ${props => props.theme.colors.primary}; */
  z-index: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 8;
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 8;
  }
`
const TextBoxCardContent = styled.div`
  border-top: 4px solid white;
  background-color: ${props => props.theme.colors.primary};
  padding-top: 80px;
  padding-right: 60px;
  padding-left: 60px;

  padding-bottom: 60px;
  /* border: 1px solid red; */
  flex: 1;
  > * {
    color: white;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding-top: 40px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 40px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const LocationRow = styled.div`
  flex-direction: row;
  width: 100%;
`

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  padding: 10px 40px 0 20px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 10px 10px 0 00px;
    width: 50%;
  }
`

const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  width: 100%;

  > p {
    color: white;
  }
  > h4 {
    margin-bottom: 10px !important;
  }
`
const DateLineBox = styled(DateBox)`
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: auto;
  }
`

const LocationContainer = styled.div`
  width: 50%;

  padding: 20px 0px 20px 20px;
  justify-content: space-between;
  align-items: center;
  > h5 {
    line-height: 10px;
    color: white;
    text-transform: none;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 16px;
      line-height: 12px;
    }
  }

  > h6 {
    line-height: 20px;
    color: white;
    font-size: 1.625rem;
    text-transform: none;
    font-weight: 300;
  }
`

const LineCrossContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;

  /* margin-bottom: 40px; */
`

const Line = styled.div`
  content: "";

  width: 100%;
  flex: 1;
  height: 1px;
  border: none;
  background-color: ${props => props.theme.colors.turquoise};
  /* @media (max-width: 1156px) {
    max-width: 100px;
  }
  @media (max-width: 1024px) {
    max-width: 140px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 50%;
  } */
`

const BackgroundPattern = styled.div`
  /* background-color: black;
  opacity: 0.4; */
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  max-height: 500px;
  /* background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEX6+vr4+Pjv7++npPQmAAAAKElEQVQI12NgCGBgZWBgEGBgZACzYWTo0tCoUAiJIs7gwMCCi02CXgD5agzBt66q6wAAAABJRU5ErkJggg=="); */
`

const BackgroundColor = styled.div`
  /* background-color: ${props => props.theme.colors.primary}; */

  position: absolute;
  display: flex;
  flex-direction: column;
  height: wrap;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    align-items: flex-start;
  }
`

const TransparentPlacer = styled.div`
  display: flex;
  width: 80%;
  height: 80px;
  z-index: 1;
  margin-top: -40px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
  }
`
// const TransparentPlacer = styled.div`
//   grid-column-start: 1;
//   grid-column-end: 4;
//   grid-row-start: 4;
//   grid-row-end: 6;
//   display: flex;
//   z-index: 1;
//   @media (max-width: ${props => props.theme.breakpoints.md}) {
//     grid-column-start: 1;
//     grid-column-end: 4;
//     grid-row-start: 5;
//     grid-row-end: 7;
//   }
// `

const TransparentBox = styled.div`
  background-color: ${props => props.theme.colors.turquoise};
  flex: 1;
  margin-left: -70px;
  margin-right: -70px;
  opacity: 0.2;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 0px;
    margin-right: 0px;
  }
`

const ImageBox = styled.div`
  /* border: 1px solid yellow; */
  z-index: 1;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 4;
  }
`
const StyledHeaderImg = styled(GatsbyImage)`
  height: auto;
  max-height: 750px;
  width: 100%;
  align-items: flex-start;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    min-height: 660px;
    /* border: 1px solid red; */
    width: 1080px;
    margin-top: -20%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-left: -15%;
    margin-top: -40%;
  }
`
const H1Header = styled.h1`
  color: ${props => props.theme.tints.white};
  line-height: 85px;
  margin-bottom: 16px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
  }
`

const PHeader = styled.p`
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  color: ${props => props.theme.tints.white};
  margin-bottom: 48px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 14px;
  }
`
