import { Container } from "@material-ui/core"
import React, { useEffect } from "react"
import styled from "styled-components"

export const InstagramFeed = () => {

  useEffect(()=>{

    const curator = `
    /* curator-feed-default-feed-layout */
    (function(){
    var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
    i.src = "https://cdn.curator.io/published/380ab0b3-23a4-470e-83be-b68bb1dfab49.js";
    e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
    })();
    `
const s = document.createElement('script');
s.type = 'text/javascript';
s.async = true;
s.innerHTML = curator;
document.body.appendChild(s);
},[])
  
  return (
    <Container >
      <Subtitle>The word on the street</Subtitle>
      <Title>What people are saying</Title>

      {/* <StyledFeed className="powr-social-feed" id="ad9fea26_1617269338"></StyledFeed> */}
    
     
<div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" className="crt-logo crt-tag" rel="noreferrer">Powered by Curator.io</a></div>

    </Container>
  )
}


const Subtitle = styled.p`
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 0px;
  }
`
const Title = styled.h3`
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 0px;
  }
`
